@keyframes beat{
  0%{
    margin-bottom:10px
  }
  20%{
    margin-bottom:5px
  }
  60%{
    margin-bottom:0
  }
  80%{
    margin-bottom:5px
  }
  100%{
    margin-bottom:10px
  }
}
