.fp-slidesNav {
  text-align: center;

  &.fp-bottom {
    bottom: 75px !important;
  }

  ul li a span {
    background-color: rgba(255, 255, 255, .75) !important;
    border: 1px solid rgba(229, 32, 32, .75) !important;
  }
}

#fp-nav {
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }

  ul {
    li {

      a span {
        background-color: rgba(255, 255, 255, .75) !important;
        border: 1px solid rgba(229, 32, 32, .75) !important;
      }

      .fp-tooltip {
        background-color: #e52020;
        text-transform: uppercase;
        padding: 3px 6px 3px 10px;
        position: absolute;
        top: -6px;
        width: 90px;
        overflow: inherit;
        display: block;

        &:after {
          left: 100%;
          top: 50%;
          border: 13px solid transparent;
          content: '';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-left-color: #e52020;
          //border-width: 13px;
          margin-top: -13px;
        }

        &.fp-right{
          right: 40px;
        }
      }
    }
  }
}

.section {
  background:linear-gradient(to bottom, rgba(71,71,71,0) 0%,rgba(71,71,71,0.21) 25%,rgba(44,44,44,0.5) 50%,rgba(19,19,19,1) 100%);
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#00474747', endColorstr='#131313',GradientType=0);
}

a.scroll-btn {
  display: block;
  width: 40px;
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 2;
  margin-left: -22px;
  text-align: center;
  font-size: 3em;
  padding-top: 5px;
  color: rgba(255, 255, 255,.5);
  animation-name: beat;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 4000ms;
  cursor: pointer;
  border-radius: 35px;
  transition:all 150ms ease;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }

  svg {
    fill: #fff;
  }
}

.slide {

  a.scroll-btn {
    left: 5.65%;
    margin-left: -22px;

    @media screen and (min-width: 768px) {
      margin-left: -33px;
    }
  }

  &.s-6 {
    a.scroll-btn {
      left: 16.95%;
      margin-left: -22px;

      @media screen and (min-width: 768px) {
        margin-left: -33px;
      }
    }
  }
}
