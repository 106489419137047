h3 {
  text-transform: uppercase;
  font-size: 1.5em;

  @media screen and (min-width: 600px) {
    font-size: 2.75em;
  }
  span {
    display: inline-block;
    background-color: #e52020;
    color: #fff;
    line-height: 1.5;
    padding: 0 1rem;
    margin: 0 0 5px 0;
    font-weight: 700;
  }
}
