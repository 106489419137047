.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, .5);
  z-index: 3;

  @media screen and (min-width: 768px) {
    height: 60px;
  }

  .logo {
    margin: 10px 30px;

    @media screen and (min-width: 768px) {
      margin: 15px 30px;
    }

    img {
      width: 150px;

      @media screen and (min-width: 768px) {
        width: 220px;
      }
    }
  }

}