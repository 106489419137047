.image-bg{
  position:absolute;
  top:50%;
  left:50%;
  margin:0;
  padding:0;
  min-width:100%;
  min-height:100%;
  width:auto;
  height:auto;
  z-index:-100;
  transform:translateX(-50%) translateY(-50%);
  background-repeat:no-repeat;
  background-size:cover;
  transition:0.5s;
}

.image-bg-slide {
  width: 11.1%;
  top: 0;
  position: absolute;
  margin: 0;
  padding: 0;
  min-height: 100%;
  //width: auto;
  height: auto;
  z-index: -100;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s;
  background-position: top left;

  &.bg-6 {
    width: 33.333%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 1;
    background: rgba(0, 0, 0, .5);
  }
}

.bg-red {
  background-color: #e52020;
}

.bg-1 {
  background-image:url('../../images/bg-01.webp');
  background-position: center center;
  @media screen and (min-width: 768px) {
    background-position: bottom left;
  }
}

.bg-2 {
  background-image:url('../../images/bg-02.webp');
  background-position: center center;
  @media screen and (min-width: 768px) {
    background-position: center left;
  }

}

.bg-3 {
  background-image:url('../../images/bg-03.webp');
  background-position: center center;
  @media screen and (min-width: 768px) {
    background-position: top center;
  }
}

.bg-4 {
  background-image:url('../../images/bg-04.webp');
  background-position: center center;
  @media screen and (min-width: 768px) {
    background-position: top left;
  }
}

.bg-5 {
  background-image:url('../../images/bg-05.webp');
  background-position: 47% center;
  @media screen and (min-width: 768px) {
    background-position: center left;
  }
}

.bg-6 {
  background-image:url('../../images/bg-06.webp');
}

.bg-7 {
  background-image:url('../../images/bg-07.webp');
  background-position: 75% center;
  @media screen and (min-width: 768px) {
    background-position: center center;
  }
}

.bg-8 {
  background-image:url('../../images/bg-08.webp');
  background-position: 30% center;
  @media screen and (min-width: 768px) {
    background-position: center center;
  }
}
