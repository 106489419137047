.box {
  background-color: rgba(255, 255, 255, .25);
  min-height: 130px;
  padding: 1rem;
  display: flex;
  align-items: center;

  @media screen and (min-width: 768px) {
    min-height: 180px;
    padding: 3rem;
    //justify-content: flex-end;
  }
}