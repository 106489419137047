
label.off-canvas-menu-label{
  position: fixed;
  right:  0;
  width: 50px;
  height: 50px;
  cursor: pointer;
  text-transform: uppercase;
  z-index:999;
  @media screen and (min-width: 768px) {
    width: 60px;
    height: 60px;
  }

  svg {
    fill: #fff;
    width: 100%;
    padding: 1rem;
    transition: .3s;
  }
}

input#off-canvas-menu{display:none;}
input#off-canvas-menu[type=checkbox]:checked ~ label.off-canvas-menu-label svg{
  transform:rotate(90deg);
}
input#off-canvas-menu[type=checkbox]:checked ~ nav.off-canvas-menu{
  transform:translateX(0);
}
div > input#off-canvas-menu[type=checkbox]:checked ~ #fullpage .section .container {
  transform:translateX(300px);
}
input#off-canvas-menu[type=checkbox]:checked ~ #fullpage .section .container > div.videobg,
input#off-canvas-menu[type=checkbox]:checked ~ #fullpage .section .container > div.imagebg{-webkit-filter:grayscale(100) blur(3px);filter:grayscale(100) blur(3px);}
input#off-canvas-menu[type=checkbox]:checked ~ .overlay{height:100%;opacity:1;}
label.off-canvas-menu-label{background-color:#e52020;}
label.off-canvas-menu-label:hover{background-color:#C72C2C;}

.off-canvas-menu{
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 600px) {
    width: 300px;
  }
  background: rgba(0,0,0,.7);
  font-size: 16px;
  transform: translateX(-100%);
  transition: .2s;
  z-index:2;

  ul{
    margin: 60px 0 0 0;
    padding: 0;

    li{
      position: relative;
      width: 100%;
      list-style: none;
      color:#fff;
      transition: .5s;
      border-top: 1px solid #555;

      a {
        display: block;
        padding: 12px 20px;
        color: #fff;
        text-decoration: none;

        &:hover,
        &:focus {
          background:#e52020 !important;
        }
      }

    }
  }
}

.off-canvas-menu {
  ul {
    li {
      &:first-child {
        border-top: none;
      }

      &:last-child {
        border-bottom: 1px solid #555;
      }
    }
  }
  input[type=checkbox] {
    position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;display:block;cursor:pointer;
  }

  input[type=checkbox]:checked ~ a:after {
    transform: rotate(45deg);
  }


}

.abstract {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  background-color: rgba(0,0,0,.5);
  text-align:center;

  @media screen and (min-width: 768px) {
      padding: 10px 30px;
      width: 100%;
      box-sizing: border-box;
  }

  h1{
    font-size: 1rem;
    margin: 0 0 1rem 0;
  }
}

address{
  color: #fff;
  font-style: normal;
  padding: 5px 0 20px 20px;
}