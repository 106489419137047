body{
  overflow-x: hidden;
  box-sizing: border-box;
  margin: 0;
  font-family: 'Ubuntu', Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  background-color: #000;
  color: #fff;
}
