.list-checked {
  list-style: none;
  padding-left: 1.25rem;

  li {
    position: relative;
    padding-left: 1.5em;  /* space to preserve indentation on wrap */

    &:before {
      content: '✓';
      color: currentColor;
      margin-right: .5rem;
      position: absolute;
      left: 0;  /* place the SVG at the start of the padding */
      width: 1em;
      height: 1em;
    }
  }
}
