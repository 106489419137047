.card {
  --bs-card-border-radius: 0;
  --bs-card-bg: rgba(255, 255, 255, .25);
  --bs-card-border-color: transparent;
  justify-content: flex-end;
  width: 100%;
  min-height: 70px;
  transition: all 150ms ease;
  @media screen and (min-width: 600px) {
    min-height: 150px;
  }

  &.logo {
    justify-content: center;
    --bs-card-border-color: transparent;
  }

  .detached {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: -1px;
    @media screen and (min-width: 768px) {
      font-size: 1.5rem;
    }
    @media screen and (min-width: 1280px) {
      font-size: 1.75rem;
    }
  }
}

.item {
  //position: relative;

  a {
    display: inline-flex;
    width: 100%;
    border: 3px solid transparent;
    text-decoration: none;
    transition: all 300ms ease;

    &:hover {
      border: 3px solid #e52020;
      transform: translate(0,-3%);

      .card {
        --bs-card-bg: #e52020;
        --bs-card-border-color: #e52020;

        .detached {
          color: #fff;
        }
      }
    }
  }
}
